import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Welkom bij Senteurs du Ciel, fokker van staande honden van het ras Braque Français Type Pyrénées."
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />        
          <Container>
            <div className="section-story-overview">
              <Row>              
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Senteurs du Ciel</h2>
                  <h3 className="description">
                    Welkom op onze website!
                  </h3>
                  <p>Wij zijn kleinschalige fokkers van de Braque Français en dan meerbepaald deze van het Pyrenese type. 
                  Naast het reeds relatief zeldzame Pyrenese type bestaat ook nog het grotere en helaas met uitsterven 
                  bedreigde Gascogne type maar dit type fokken wij niet.</p>
                  <p>Deze honden worden ook wel Franse Pointers genoemd want dat is wat ze eigenlijk zijn. Dit zijn staande honden (FCI groep 7) net zoals bijvoorbeeld 
                  Duitse Staande Kortharen, Draadharen en Langharen alsook Weimaraners, Vizsla’s en natuurlijk ook Engelse Pointers.
                  </p>
                  <p>De Braque Français Type Pyrénées is een kleinere, compactere staande hond dan de eerder genoemde Duitse Staande en Weimaraners. 
                  Ze zijn echter zeer licht, ontzettend energiek en bijgevolg ook razendsnel. Op de jacht worden ze ingezet om grotere percelen af te jagen 
                  op zoek naar allerhande kleinwild zoals fazant en patrijs maar ook snip wat in thuisland Frankrijk als hun specialiteit beschouwd wordt. 
                  Zelf jagen we er ook nog mee op winter grouse en auerhoen in Zweden. Kortom: deze honden zijn uitstekend geschikt voor de gepassioneerde kleinwildjager!
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <img
                  alt="3 honden van het type Braque Français Type Pyrénées gefokt door jachthondenkennel Senteurs du Ciel"
                  src={require("assets/img/index-team.webp")}
                ></img>
              </Col>              
            </Row>
            <div className="section-story-overview">
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <p>Daarnaast is de Franse Pointer en dan zeker deze van het Pyrenese type een heel zachte, lieve én 
                  kindvriendelijke hond die naast de jacht ook uitstekend tot zijn recht komt bij actieve gezinnen. 
                  Zelf actief ingesteld zijn is wel noodzakelijk want vergis u niet: dit zijn honden die hun dagelijkse 
                  portie beweging nodig hebben. Met een blokje rond elke dag komen deze honden zeker niet toe! Bent u echter 
                  zelf sportief ingesteld en houdt u van het buitenleven dan kan dit zeldzame ras een mooi alternatief zijn 
                  voor de meer bekende rassen uit FCI groep 7.</p>
                  <p>Wij garanderen u een mooie pup met een stabiel karakter die uitstekend geschikt is voor zowel de jacht als 
                  het actieve buitenleven. Daarnaast blijven wij ook gedurende de ganse levensduur van uw pup ter beschikking 
                  voor al uw vragen en wij kunnen u dan ook helpen met de opvoeding en training van uw jonge jachthond. 
                  Zo helpen wij graag om uw pup te laten opgroeien tot een volwaardige kameraad voor de jacht en/of een 
                  aangenaam lid van het gezin!</p>
                  <p>Wij fokken op kwaliteit en niet op kwantiteit wat betekent dat wij slechts om de 2 à 3 jaren een nest plannen. 
                  We plannen pas een volgend nest in, ten vroegste, 2026 en dit enkel als onze laatste aanwinst, Lilith, aan al onze eisen voldoet.</p>
                  <p>In het kader van die kwaliteit zijn wij natuurlijk ook aangesloten bij de Koninklijke Maatschappij 
                  Sint-Hubertus (KMSH) waarbij wij veel verder gaan dan de eisen die door de KMSH gesteld worden om te mogen 
                  fokken. Zo fokken wij enkel met honden die zowel op schoonheid én werk aan onze eisen voldoen en 
                  dit ook daadwerkelijk bewezen hebben op shows en wedstrijden!</p>
                </Col>
              </Row>
            </div>
          </Container>
        
        <div className="text-center">
          <Container>
            <h2 className="title">Ons team</h2>
            <div className="team">
              <Row>
                <Col md="6">
                  <div className="team-player">
                    <img
                      alt="De stammoeder van onze jachthondenkennel: Juno du Vallon de Beaudini"
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/team-juno.webp")}
                    ></img>
                    <h4 className="title">Juno du Vallon de Beaudini</h4>
                    <p className="category text-info">Stammoeder</p>
                    <p className="description">
                      Juno is de stammoeder van onze kennel. Zij is een prachtige combinatie van karakter, 
                      jachtpassie en schoonheid en dat is niet enkel onze mening maar ook die van tal van keurmeesters in 
                      binnen- en buitenland en dit zowel op werkvlak (veldwerk) als schoonheid.<br/><br/>
                      Juno is een fijne teef met een schijnbaar onuitputbare voorraad energie. Zolang ze die dagelijks 
                      kwijt kan (en daar zorgen wij natuurlijk voor), is het ook een heerlijk hondje binnenshuis dat niets liever heeft 
                      dan lekker verwend te worden.<br/><br/>
                      Neem ze mee naar buiten en ze verandert in een ontzettend energiek hondje: zet ze in een veld en ze jaagt 
                      het vol passie af op zoek naar veerwild, neem ze mee naar een bos en ze duikt de dekking in om na te gaan 
                      waar die geluiden van ritselende bladeren vandaan komen!
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="team-player">
                    <img
                      alt="Teef Qetesh des Senteurs du Ciel, de Braque Français Type Pyrénées die we zelf gehouden hebben uit ons eerste nest"
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/team-qetesh.webp")}
                    ></img>
                    <h4 className="title">Qetesh des Senteurs du Ciel</h4>
                    <p className="category text-info">Diva</p>
                    <p className="description">
                      Qetesh is het teefje dat wij hebben gehouden uit onze eerste generatie eigen fok. 
                      Zij komt voort uit de combinatie van Juno du Vallon de Beaudini en Gibus du Domaine de Saint Louis.<br/><br/>
                      Qetesh is uitgegroeid tot een mooie teef, zo werd ze zelfs World Winner op de World Dog Show te Amsterdam! 
                      Ook in het veld laat Qetesh aanleg zien en is ze ondertussen veldwerk kampioen geworden!<br/><br/>
                      Ze heeft ook een wat heviger en speelser karakter dan haar moeder maar tegelijkertijd kan ze ook heel zacht en lief zijn 
                      (zoals bijvoorbeeld met de puppies van Juno’s tweede nestje). Maar, net als mama Juno, is ze ook een heerlijk 
                      aanhankelijk hondje!
                    </p>                    
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="team-player">
                    <img
                      alt="Teef Skadi des Senteurs du Ciel, de Braque Français Type Pyrénées die we zelf gehouden hebben uit ons tweede nest"
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/team-skadi.webp")}
                    ></img>
                    <h4 className="title">Skadi des Senteurs du Ciel</h4>
                    <p className="category text-info">Kwajongen</p>
                    <p className="description">
                      Skadi is een tweede teefje dat wij hebben gehouden uit onze eerste generatie eigen fok. 
                      Zij komt voort uit de combinatie van Juno du Vallon de Beaudini en Loustic des Remises d’Arconie.<br/><br/>
                      Skadi is het teefje dat ons het meest wist te bekoren in de combinatie karakter, uiterlijk en jachtaanleg. 
                      Ze is ondertussen uitgegroeid tot een mooi, compact hondje met een uiterst actief karakter.<br/><br/>
                      Binnenshuis is ze heerlijk aanhankelijk maar van zodra je buiten komt, kent ze enkel nog rennen en jagen. 
                      We hebben besloten om Skadi niet in de fok in te zetten maar ze blijft wel, zoals al onze honden, 
                      bij ons om ten volle van haar leven als gezins- en jachthond te kunnen genieten.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="team-player">
                    <img
                      alt="Teef Wakan Lilith des Senteurs du Ciel, de Braque Français Type Pyrénées die we zelf gehouden hebben uit ons derde nest"
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/team-lilith.webp")}
                    ></img>
                    <h4 className="title">Wakan Lilith des Senteurs du Ciel</h4>
                    <p className="category text-info">Energiebom</p>
                    <p className="description">
                      Lilith is een dochter van Qetesh en ons eerste teefje van de tweede generatie.
                      Haar vader is Nestor du Milobre de Bouisse en, net als alle honden waar wij mee samenwerken, een uitstekende jachthond en werkkampioen.<br/><br/>
                      Lilith viel ons al snel op binnen het nest met haar mooie bouw en energiek karakter. We zien in haar ook heel wat (positieve) kenmerken van zowel 
                      haar mama Qetesh als grootmoeder Juno.<br/><br/>
                      Het is nu dan ook afwachten hoe Lilith verder ontwikkelt maar wij hopen dat ze in de voetsporen van zowel mama als grootmoeder treedt en dit zowel 
                      in werk als in schoonheid.<br/><br/>                      
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>        
        <DarkFooter />
      </div>
    </>
  );
}

export default Index;
