import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import LilithHeader from "components/Headers/LilithHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import SEO from "components/SEO.js";

function Lilith() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SEO 
        title="Jachthondenkennel Senteurs du Ciel"
        description="Alle informatie over Skadi des Senteurs du Ciel, teefje uit het tweede nest en onze eerste sterk bruin gespikkelde Braque Français Type Pyrénées."
        type="article" />
      <IndexNavbar />
      <div className="wrapper">
        <LilithHeader />
        <Container>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Lilith des Senteurs du Ciel</h2>
                <h3 className="description">In resultaten</h3>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="4">
                <h4 className="title">Veldwerk</h4>
                <p>Voorlopig geen resultaten</p>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="4">
                <img
                  alt="Lilith des Senteurs du Ciel, Braque Français Type Pyrénées teef loopt richting de camera"
                  src={require("assets/img/lilith-2.webp")}
                ></img>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="4">
                <h4 className="title">Schoonheid</h4>
                <h5 className="description">Titels</h5>
                <p>Belgisch Jeugdkampioen 2024</p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Wakan Lilith des Senteurs du Ciel</h2>
                <h3 className="description">In woord en beeld</h3>
                <h4 className="description">Onze tweede generatie</h4>
                <h5 className="description">Waarom we Lilith hebben gekozen</h5>
                <p>Lilih werd geboren in een kleiner nestje van 6 pups uit Qetesh des Senteurs du Ciel en Nestor du
                  Milobre de Bouisse. Dit zou ons eerste teefje van de tweede generatie worden en hoewel het ons kleinste
                  nest tot nog toe was, hadden we met 4 teefjes weer heel wat keuze.</p>
                <p>Maar eigenlijk viel al snel, in de eerste 3 weken, de aandacht op Lilith en  dat is niet meer veranderd. Al
                  was dat onder andere ook wel omdat Lilith een wilde brokkenpiloot bleek te zijn waarmee we al vroeg de eerste
                  bezoeken aan de dierenarts dienden te brengen. Gelukkig bleek dat allemaal niet te ernstig te zijn en konden
                  we dan ook vooral weer genieten van een toch wel zeer actieve bende pups. We moeten het bekennen: dit nest van 6
                  heeft ons veel meer energie gekost dan het vorige nest van 10!
                </p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <img
                alt="Wakan Lilith des Senteurs du Ciel, Braque Français Type Pyrénées, spelend in een veld"
                src={require("assets/img/lilith-3.webp")}
              ></img>
            </Col>
          </Row>
          <div className="section-story-overview">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <p>We deden ook deze keer onze tests waarbij we de pups mee het veld innamen, ze lieten zwemmen, ze in contact brachten
                  met zowel dood als levend wild, ... en ook deze keer zag dat er eigenlijk voor alle 6 de pups goed uit. Maar voor ons stak
                  Lilith er bij de teefjes zowel qua fysieke bouw als qua pittig karakter boven uit en dus was de keuze deze keer snel
                  gemaakt.
                </p>
                <p>Met het ganse lockdown-gebeuren finaal achter de rug, konden we ons nu eindelijk ook weer ten volle beginnen toeleggen
                  op het trainen voor zowel show als werk. Training voor het werk is nog volop aan de gang maar qua schoonheid kunnen we al
                  met enige trots meedelen dat Lilith ondertussen reeds Belgisch Jeugdkampioen is geworden. Dat ziet er dus goed uit voor de
                  verdere toekomst!
                </p>
                <p>Net als Skadi, overigens 8 poten op 1 buik, is Lilith een hele handvol. We dachten eigenlijk niet dat het nog heftiger en
                  energieker kon dan Skadi maar we hadden ons duidelijk vergist! Daarnaast heeft ze ook een aardige portie diva-streken
                  meegekregen van haar moeder Qetesh en als ze in Hollywood ooit een hondje nodig hebben voor een drama-rol dan mogen ze ons
                  zeker ook contacteren! Kortom: ook Lilith is een hele handvol maar, net als al onze teefjes eigenlijk, tegelijkertijd ook
                  heel lief en aanhankelijk.
                </p>
                <p>Ons heeft ze alvast helemaal ingepalmd. Ook de eerste indrukken in het veld zijn positief maar verdere training is nodig om te
                  zien of ze het ook kan waarmaken in het veldwerk circuit. Maar een degelijke praktische jachthond ga ik er zeker wel aan hebben.
                  Zoveel is nu reeds duidelijk.
                </p>
              </Col>
            </Row>
          </div>          
        </Container>
        <DarkFooter />
      </div>
    </>
  );
}

export default Lilith;
